/* eslint-disable @typescript-eslint/no-var-requires */
import { Block, filterBlocks, parseSanitisedBlocks, SanitisedBlock } from '@/lib/parsers/blocks';
import { assertUnreachable } from '@liquorice/allsorts-craftcms-nextjs';

const RichText = lazy(() => import('../RichText')); // import RichText from '../RichText';
const AccordionBlock = lazy(() => import('./BlockComponents/AccordionBlock')); // import AccordionBlock from './BlockComponents/AccordionBlock';
const ContentListBlock = lazy(() => import('./BlockComponents/ContentListBlock')); // import ContentListBlock from './BlockComponents/ContentListBlock';
const DualCardsBlock = lazy(() => import('./BlockComponents/DualCardsBlock')); // import DualCardsBlock from './BlockComponents/DualCardsBlock';
const EntryCardBlock = lazy(() => import('./BlockComponents/EntryCardBlock')); // import EntryCardBlock from './BlockComponents/EntryCardBlock';
const EntryCardsBlock = lazy(() => import('./BlockComponents/EntryCardsBlock')); // import EntryCardsBlock from './BlockComponents/EntryCardsBlock';
const ExternalCardBlock = lazy(() => import('./BlockComponents/ExternalCardBlock')); // import ExternalCardBlock from './BlockComponents/ExternalCardBlock';
const ExternalPresenterCardBlock = lazy(
  () => import('./BlockComponents/ExternalPresenterCardBlock')
); // import ExternalPresenterCardBlock from './BlockComponents/ExternalPresenterCardBlock';
const FormBlock = lazy(() => import('./BlockComponents/FormBlock')); // import FormBlock from './BlockComponents/FormBlock';
const ImageAndTextBlock = lazy(() => import('./BlockComponents/ImageAndTextBlock')); // import ImageAndTextBlock from './BlockComponents/ImageAndTextBlock';
const ImageBlock = lazy(() => import('./BlockComponents/ImageBlock')); // import ImageBlock from './BlockComponents/ImageBlock';
const ImageCarouselBlock = lazy(() => import('./BlockComponents/ImageCarouselBlock')); // import ImageCarouselBlock from './BlockComponents/ImageCarouselBlock';
const KeyTakeawaysBlock = lazy(() => import('./BlockComponents/KeyTakeawaysBlock')); // import KeyTakeawaysBlock from './BlockComponents/KeyTakeawaysBlock';
const LargeAccordionBlock = lazy(() => import('./BlockComponents/LargeAccordionBlock')); // import LargeAccordionBlock from './BlockComponents/LargeAccordionBlock';
const LatestArticlesBlock = lazy(() => import('./BlockComponents/LatestArticlesBlock')); // import LatestArticlesBlock from './BlockComponents/LatestArticlesBlock';
const PdfDownloadBlock = lazy(() => import('./BlockComponents/PdfDownloadBlock')); // import PdfDownloadBlock from './BlockComponents/PdfDownloadBlock';
const PdfEmbedBlock = lazy(() => import('./BlockComponents/PdfEmbedBlock')); // import PdfEmbedBlock from './BlockComponents/PdfEmbedBlock';
const PodcastBlock = lazy(() => import('./BlockComponents/PodcastBlock')); // import PodcastBlock from './BlockComponents/PodcastBlock';
const PreferenceManagerBlock = lazy(() => import('./BlockComponents/PreferenceManagerBlock')); // import PreferenceManagerBlock from './BlockComponents/PreferenceManagerBlock';
const ProfileCardBlock = lazy(() => import('./BlockComponents/PresenterCardBlock')); // import ProfileCardBlock from './BlockComponents/PresenterCardBlock';
const PresenterCardsBlock = lazy(() => import('./BlockComponents/PresenterCardsBlock')); // import PresenterCardsBlock from './BlockComponents/PresenterCardsBlock';
const ProfileCardsBlock = lazy(() => import('./BlockComponents/ProfileCardsBlock')); // import ProfileCardsBlock from './BlockComponents/ProfileCardsBlock';
const QuoteBlock = lazy(() => import('./BlockComponents/QuoteBlock')); // import QuoteBlock from './BlockComponents/QuoteBlock';
const SocialLinksBlock = lazy(() => import('./BlockComponents/SocialLinksBlock')); // import SocialLinksBlock from './BlockComponents/SocialLinksBlock';
const TextCardBlock = lazy(() => import('./BlockComponents/TextCardBlock')); // import TextCardBlock from './BlockComponents/TextCardBlock';
const TextCardsBlock = lazy(() => import('./BlockComponents/TextCardsBlock')); // import TextCardsBlock from './BlockComponents/TextCardsBlock';
const VideoBlock = lazy(() => import('./BlockComponents/VideoBlock')); // import VideoBlock from './BlockComponents/VideoBlock';

import { Entry } from '@/lib/parsers/entries';
import { lazy } from 'react';
import MediaReleaseStatementBlock from './BlockComponents/MediaReleaseStatementBlock';
import { BlockProvider, BlocksContextProvider } from './useBlock';

export const BlockInner = (block: Block) => {
  switch (block.__typename) {
    // Components from the block components directory
    case 'blocks_dualCards_BlockType':
      return <DualCardsBlock {...block} />;
    case 'blocks_accordion_BlockType':
      return <AccordionBlock {...block} />;
    case 'blocks_largeAccordion_BlockType':
      return <LargeAccordionBlock {...block} />;
    case 'blocks_entryCard_BlockType':
      return <EntryCardBlock {...block} />;
    case 'blocks_presenterCard_BlockType':
      return <ProfileCardBlock {...block} />;
    case 'blocks_externalPresenterCard_BlockType':
      return <ExternalPresenterCardBlock {...block} />;
    case 'blocks_externalCard_BlockType':
      return <ExternalCardBlock {...block} />;
    case 'blocks_entryCards_BlockType':
      return <EntryCardsBlock {...block} />;
    case 'blocks_presenterCards_BlockType':
      return <PresenterCardsBlock {...block} />;
    case 'blocks_image_BlockType':
      return <ImageBlock {...block} />;
    case 'blocks_latestArticles_BlockType':
      return <LatestArticlesBlock {...block} />;
    case 'blocks_profileCards_BlockType':
      return <ProfileCardsBlock {...block} />;
    case 'blocks_video_BlockType':
      return <VideoBlock {...block} />;
    case 'blocks_contentList_BlockType':
      return <ContentListBlock {...block} />;
    case 'blocks_imageCarousel_BlockType':
      return <ImageCarouselBlock {...block} />;
    case 'blocks_imageAndText_BlockType':
      return <ImageAndTextBlock {...block} />;
    case 'blocks_quote_BlockType':
      return <QuoteBlock {...block} />;
    case 'blocks_keyTakeaways_BlockType':
      return <KeyTakeawaysBlock {...block} />;
    case 'blocks_textCard_BlockType':
      return <TextCardBlock {...block} />;
    case 'blocks_textCards_BlockType':
      return <TextCardsBlock {...block} />;
    case 'blocks_pdfDownload_BlockType':
      return <PdfDownloadBlock {...block} />;
    case 'blocks_pdfEmbed_BlockType':
      return <PdfEmbedBlock {...block} />;
    case 'blocks_podcast_BlockType':
      return <PodcastBlock {...block} />;
    case 'blocks_socialLinks_BlockType':
      return <SocialLinksBlock {...block} />;
    case 'blocks_form_BlockType':
      return <FormBlock {...block} />;
    case 'blocks_preferenceManager_BlockType':
      return <PreferenceManagerBlock {...block} />;
    // Components from the components directory
    case 'blocks_richText_BlockType':
      return <RichText {...block} />;

    case 'blocks_mediaReleaseStatement_BlockType':
      return <MediaReleaseStatementBlock {...block} />;
    default:
      return assertUnreachable(block);
  }
};

type BlocksProps = {
  blocks?: Block[];
  entry?: Entry;
};

const Blocks = ({ blocks = [], entry, ...context }: BlocksProps) => {
  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);
  const filteredBlocks = filterBlocks(parsedBlocks, entry).blocks;

  const blocksOutput = filteredBlocks.map((v, i) => (
    <BlockProvider key={i + 1} value={v}>
      <BlockInner {...v.block} />
    </BlockProvider>
  ));

  return filteredBlocks.length ? (
    <BlocksContextProvider value={context}>{blocksOutput}</BlocksContextProvider>
  ) : (
    <></>
  );
};

export const BlockChildren = Blocks;

export default Blocks;
